import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Field, useField } from "formik";
import { Checkbox, FormHelperText, ListItemText, Tooltip } from "@mui/material";
import { useEffect } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
//HELPER TEXT FOR PROPS
// const variableList = [
//     {value:100,name:"Oliver Hansen"},
//     {value:101,name: "Van Henry"},

//   ];
// props=(mode="read"   data={ {name:"namehere", label:"labelhere", list:variableList} }  defaultValue={[array of values]} )

export default function CustomMultiSelectCheck({ selectAllFlag, ...props }) {
  const data = props.data;
  const { t } = useTranslation();
  const defaultValue = props.defaultValue || [];

  const mode = props.mode;
  let readMode;
  if (mode === "read") {
    readMode = true;
  } else {
    readMode = false;
  }

  // const ITEM_HEIGHT = 48;
  const ITEM_HEIGHT = 70;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const CustomizedMultiSelectForFormik = ({
    field,
    form,
    children,
    ...props
  }) => {
    const { name, value } = field;
    //console.log(value, "vvvvvvv", typeof value);
    const { setFieldValue } = form;
    const list = props.list;
    const [, meta] = useField(name);
    var flag = meta.error && meta.touched ? true : false;
    //console.log(list, "CustomizedMultiSelectForFormik here");
    const [currentNames, setCurrentNames] = React.useState([]);
    const handleChange = (event) => {
      const value = event.target.value;
      setFieldValue(name, typeof value === "string" ? value.split(",") : value);
    };
    useEffect(() => {
      if (typeof value === "undefined" || !value) {
        setFieldValue(name, defaultValue);
        //console.log("yes here undefined");
      } else if (!Array.isArray(value)) {
        //console.log(value, "valllll");
        var temp = [];
        var nameArr = value.split(",");
        nameArr.map((data, index) => {
          var tt = list.find((element) => element.value === data);
          if (typeof tt !== "undefined") {
            temp.push(tt.value);
          }
          //console.log(tt, "tttttt");
        });
        //console.log(temp, "final arrrr");
        setFieldValue(name, temp);
        //console.log("yes here it is string");
      }
    }, [value]);

    return Array.isArray(value) && value ? (
      <>
        {/* <Tooltip title={currentNames} placement="bottom" arrow> */}
        <Select
          name={name}
          multiple
          value={value || defaultValue}
          onChange={handleChange}
          MenuProps={MenuProps}
          renderValue={(selected) => {
            let arr = [];
            //console.log(selected, "selected multiselect ssss");
            {
              selected.map((select, index) => {
                var temp = list.find((element) => element.value === select);
                if (typeof temp !== "undefined") {
                  arr.push(temp.name);
                }
              });
            }
            setCurrentNames(arr.join(", "));
            return arr.join(", ");
          }}
          {...props}
          // input={<OutlinedInput label="Name" />}
        >
          {selectAllFlag && list.length !== 0 ? (
            <MenuItem
              onClick={() => {
                const allValues = list.map((item) => item.value);
                const allSelected = value.length === list.length;
                setFieldValue(name, allSelected ? [] : allValues);
              }}
            >
              <Checkbox checked={value.length === list.length} />
              <ListItemText primary={t("Select All")} />
            </MenuItem>
          ) : null}

          {list.map((list, index) => (
            <MenuItem value={list.value} key={index}>
              <Checkbox checked={value.indexOf(list.value) > -1} />
              <ListItemText primary={list.name} />
            </MenuItem>
          ))}
        </Select>
        {/* </Tooltip> */}
        {flag ? (
          <FormHelperText sx={{ color: "red" }}>{meta.error}</FormHelperText>
        ) : (
          <></>
        )}
      </>
    ) : (
      <>{/* {console.log(value, "hereee")} */}</>
    );
  };
  return (
    <>
      <div>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">{data.label}</InputLabel>
          <Field
            name={data.name}
            component={CustomizedMultiSelectForFormik}
            label={data.label}
            inputProps={{
              readOnly: readMode,
            }}
            list={data.list}
          ></Field>
        </FormControl>
      </div>
    </>
  );
}

// return (
//   <>
//     {value && (
//       <Select
//         name={name}
//         multiple
//         value={value || defaultValue}
//         onChange={handleChange}
//         MenuProps={MenuProps}
//         // renderValue={(selected) => {
//         //   let arr = [];
//         //   console.log(selected, "selected multiselect ssss");
//         //   {
//         //     selected.map((select, index) => {
//         //       arr[index] = list.find(
//         //         (element) => element.value === select
//         //       ).name;
//         //     });
//         //   }
//         //   return arr.join(", ");
//         // }}
//         {...props}
//         // input={<OutlinedInput label="Name" />}
//       >
//         {list.map((list, index) => (
//           <MenuItem value={list.value} key={index}>
//             <Checkbox checked={value.indexOf(list.value) > -1} />
//             <ListItemText primary={list.name} />
//           </MenuItem>
//         ))}
//       </Select>
//     )}
//   </>
// );
