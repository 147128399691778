import * as React from "react";
import {
  DataGrid,
  GridPagination,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { AxiosFetch } from "../../AxiosFetch";
import { useContext } from "react";
import LoginContext from "../../../context/LoginContext";
import { useState, useEffect } from "react";
import DeleteDialog from "../../commonComponents/DeleteDialog";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
  Grid,
} from "@mui/material";
import { SearchContext } from "./SerachIndexWrapper";

function CustomToolbar({
  onShowSelectedCols,
  showSelectedColsBtn = "Select Columns",
}) {
  return (
    <GridToolbarContainer
      style={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button
        variant="contained"
        color="primary"
        // disabled={disableDeleteButton}
        // startIcon={<DeleteIcon />}
        onClick={onShowSelectedCols}
        style={{ marginLeft: 8 }}
      >
        {showSelectedColsBtn}
      </Button>
      <GridPagination
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </GridToolbarContainer>
  );
}

export default function CustomerSearchTable({ handleRowDelete, ...props }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();
  const context = useContext(LoginContext);
  const [rows, setRows] = useState(props.rows);
  const [delLoading, setDelLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectRow, setSelectRow] = useState({});

  const [columnSelectorOpen, setColumnSelectorOpen] = useState(false);
  const { userList } = useContext(SearchContext);

  const handleDialogOpen = (e) => {
    setDialogOpen(e);
  };

  const cw = 100;
  const allColumns = [
    { field: "customerId", headerName: "ID", flex: 0.5 },
    { field: "customerLastName", headerName: `${t("Last Name")}`, flex: 0.9 },
    { field: "customerFirstName", headerName: `${t("First Name")}`, flex: 0.9 },
    { field: "customerRegisterDateTime", headerName: `${t("Case Linkage Date")}`, flex: 1.4 },
    {
      field: "telephone",
      headerName: `${t("Telephone")}`,
      // type: 'number',
      valueGetter: (params) => {
        var temp = "";
        if (params.row.telephone.length !== 0) {
          temp = params.row.telephone[0].telNum;
        }

        return temp;
      },
      flex: 1,
    },
    {
      field: "customerModifyDateTime",
      headerName: `${t("Topic Last Updated")}`,
      type: "datetime",
      flex: 1,
      valueGetter: (params) => {
        // Extract the topic array from the row data
        const topics = params.row.topic;

        // Find the latest modifyDateTime
        const latestDate = topics.reduce((latest, current) => {
          return current.modifyDateTime > latest
            ? current.modifyDateTime
            : latest;
        }, "");
        return latestDate;
      },
    },
    {
      field: "topicLatestPhase",
      headerName: `${t("Latest Phase")}`,
      flex: 1,
      valueGetter: (params) => {
        // Extract the topic array from the row data
        const topics = params.row.topic;
        // Find the latest phase
        const latestTopic = topics.reduce((latest, current) => {
          return current.modifyDateTime > latest.modifyDateTime
            ? current
            : latest;
        }, topics[0]);
        if (latestTopic) {
          return latestTopic.phase;
        } else {
          return "";
        }
      },
    },
    {
      field: "customerNextCallDateTime",
      headerName: `${t("Next Call Date")}`,
      type: "date",
      flex: 1,
      valueGetter: (params) => calculateNextCallDate(params.row.topic),
    },
    { field: "customerAddress1", headerName: `${t("Address 1")}`, flex: 1 },
    { field: "customerAddress2", headerName: `${t("Address 2")}`, flex: 1 },
    {
      field: "customerGroup",
      headerName: `${t("Group Name")}`,
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.customerGroup !== null) {
          temp = params.row.customerGroup.groupDisplayName;
        }
        return temp;
      },
    },
    // {
    //   field: "customerFirstUserId",
    //   headerName: `${t("Customer First User Id")}`,
    //   flex: 0.9,
    // },
    {
      field: "customerFirstUserFullName",
      headerName: `${t("Customer First User Name")}`,
      flex: 0.9,
    },
    // {
    //   field: "customerSecondUserId",
    //   headerName: `${t("Customer Second User Id")}`,
    //   flex: 0.9,
    // },
    {
      field: "customerSecondUserFullName",
      headerName: `${t("Customer Second User Name")}`,
      flex: 0.9,
    },
    // {
    //   field: "customerSecondUserId",
    //   headerName: `${t("Customer Second User Id")}`,
    //   flex: 0.9,
    // },
    // {
    //   field: "customerSecondUserFullName",
    //   headerName: `${t("Customer Second User Name")}`,
    //   flex: 0.9,
    // },
    {
      field: "project",
      headerName: `${t("Project Group")}`,
      flex: 1,
      valueGetter: (params) => {
        var temp = "";
        if (params.row.project !== null) {
          temp = params.row.project.name;
        }
        return temp;
      },
    },
    {
      field: "edit",
      headerName: `${t("Edit / Details")}`,
      sortable: false,
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          console.log(params, "ppppp");
          var arr = JSON.parse(sessionStorage.getItem("searchArrayId"));
          var indexId = arr.findIndex((e) => e === params.row.customerId);
          sessionStorage.setItem("indexId", indexId);

          navigate("/customerDetail", {
            state: {
              customerId: params.row.customerId,
            },
          });
        };
        return (
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<EditIcon />}
            onClick={onClick}
          >
            {t("Edit")}
          </Button>
        );
      },
    },
    // {
    //   field: "delete",
    //   headerName: `${t("Delete")}`,
    //   sortable: false,
    //   flex: 0.8,
    //   headerAlign: "center",
    //   align: "center",
    //   renderCell: (params) => {
    //     const onClick = async (e) => {
    //       e.stopPropagation();
    //       setDialogOpen(true);
    //       setSelectRow(params.row);
    //     };
    //     return (
    //       <Button
    //         variant="contained"
    //         color="error"
    //         size="small"
    //         startIcon={<DeleteIcon />}
    //         onClick={onClick}
    //       >
    //         {t("Delete")}
    //       </Button>
    //     );
    //   },
    //   flex: 1,
    // },
  ];
  // Define your default columns
  const defaultColumns = [
    "customerId",
    "customerLastName",
    "customerFirstName",
    "telephone",
    "customerModifyDateTime",
    "topicLatestPhase",
    "customerAddress1",
    "customerGroup",
    "customerFirstUserFullName",
    "project",
    "edit",
    "delete",
  ];

  const [selectedColumns, setSelectedColumns] = useState(() => {
    // Retrieve columns from local storage
    const storedColumns = localStorage.getItem("selectedColumns");
  
    try{
      if (storedColumns) {
    
        const parsedColumns = JSON.parse(storedColumns);
        // Use stored columns only if they include 'edit'
        if (parsedColumns.includes("edit")) {
  
          return storedColumns ? JSON.parse(storedColumns) : JSON.parse(storedColumns);
        }
        else{
          return storedColumns ? defaultColumns : defaultColumns;
        }
      }
      else{
        return storedColumns ? defaultColumns : defaultColumns;
      }
    }
    catch (error){
      console.log("error in parsing")
      return storedColumns ? defaultColumns : defaultColumns;
    }
   
   
  });

  // Function to handle column selection
  const handleColumnSelection = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setSelectedColumns((prev) => [...prev, name]);
    } else {
      setSelectedColumns((prev) => prev.filter((col) => col !== name));
    }
  };

  // Save selected columns to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  useEffect(() => {
    if (!userList || userList.length === 0) {
      setRows(props.rows);
      return; // Skip execution if userList is null or empty
    }
    console.log("rows", props.rows);

    // Map through the rows and add customerFirstUserFullName
    const updatedRows = props.rows.map((row) => {
      // Find the user corresponding to customerFirstUserId
      const user = userList.find(
        (user) => user.userId === row.customerFirstUserId
      );

      const user2 = userList.find(
        (user) => user.userId === row.customerSecondUserId
      );

      // If user is found, concatenate LastName and FirstName
      const customerFirstUserFullName = user
        ? `${user.lastName} ${user.firstName}`
        : row.customerFirstUserId;

      const customerSecondUserFullName = user2
        ? `${user2.lastName} ${user2.firstName}`
        : row.customerSecondUserId;

      // Return the updated row with the new property
      return {
        ...row,
        customerFirstUserFullName,
        customerSecondUserFullName,
      };
    });

    setRows(updatedRows);
  }, [props.rows, userList]);

  // Get the current columns based on selection
  const currentColumns = [
    ...allColumns.filter((col) => selectedColumns.includes(col.field)),
  ];

  const handleDelete = async () => {
    setDelLoading(true);
    // don't select this row after clicking
    console.log(selectRow, "ppppp");
    try {
      const response = await axiosFetch.delete(
        `/customers/soft/${selectRow.customerId}`
      );
      console.log(response, "rrrr");
      {
        response &&
          context.setGlobalAlert({
            open: true,
            severity: "success",
            title: "success",
            message: `${response.data}`,
          });
      }
      handleRowDelete(selectRow);
      setDelLoading(false);
    } catch (error) {
      context.setGlobalAlert({
        open: true,
        severity: "error",
        title: "error",
        message: `${error.response.data.errorMessage}`,
      });
      console.log(error, "error");
      setDelLoading(false);
    }
    setDialogOpen(false);
  };

  const [pageSize, setPageSize] = useState(
    sessionStorage.getItem("tablePageSize") || 100
  );

  return (
    <>
      {/* <LinearProgress
        color="error"
        sx={{ visibility: `${!delLoading ? "hidden" : ""}` }}
      /> */}
      <DeleteDialog
        handleDialogOpen={handleDialogOpen}
        handleDelete={handleDelete}
        dialogOpen={dialogOpen}
        delLoading={delLoading}
      />
      <Dialog
        open={columnSelectorOpen}
        onClose={() => setColumnSelectorOpen(false)}
      >
        <DialogTitle>{t("Selected Columns")}</DialogTitle>
        <DialogContent>
          <FormGroup>
            <Grid container spacing={2}>
              {[0, 1, 2].map((rowIndex) => (
                <Grid item xs={4} key={rowIndex}>
                  {allColumns
                    .filter((_, index) => index % 3 === rowIndex)
                    .filter(column => column.field.toLowerCase() !== "edit") 
                    .map((column) => (
                      <FormControlLabel
                        key={column.field}
                        control={
                          <Checkbox
                            checked={selectedColumns.includes(column.field)}
                            onChange={handleColumnSelection}
                            name={column.field}
                          />
                        }
                        label={column.headerName}
                      />
                    ))}
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setColumnSelectorOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          height: pageSize === 20 ? 500 : "85dvh",
          width: "100%",
        }}
      >
        <DataGrid
          getRowId={(row) => row.customerId}
          rows={rows}
          columns={currentColumns}
          pageSize={pageSize}
          rowsPerPageOptions={[20, 50, 100]}
          pagination
          onPageSizeChange={(val) => {
            setPageSize(val);
            sessionStorage.setItem("tablePageSize", val);
          }}
          components={{
            Footer: CustomToolbar, // Use the custom toolbar
          }}
          componentsProps={{
            footer: {
              onShowSelectedCols: () => setColumnSelectorOpen((prev) => !prev),
              showSelectedColsBtn: `${t("Selected Columns")}`,
            },
          }}
          sx={{
            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                "margin-top": "1em",
                "margin-bottom": "1em",
              },
          }}
          //checkboxSelection
        />
      </div>
    </>
  );
}
function calculateNextCallDate(topicArr) {
  let allDatesArr = [];
  const today = new Date();
  topicArr.forEach(({ tickets }, index) => {
    tickets.forEach(({ nextCallDate }) => {
      if (nextCallDate) {
        allDatesArr.push(new Date(nextCallDate));
      }
    });
  });
  allDatesArr.sort((a, b) => a - b);
  const futureDates = allDatesArr.filter((date) => date > today);
  //console.log(futureDates, "fffff", today, allDatesArr);
  if (futureDates.length > 0) {
    // If there are future dates, return the earliest one
    return futureDates[0];
  } else {
    // If there are no future dates, return the most recent past date
    const pastDates = allDatesArr.filter((date) => date < today);
    return pastDates.length > 0 ? pastDates[pastDates.length - 1] : null;
  }
}
